import "./suggest_formula";

webix.protoUI({
	name: "live-editor",
	$cssName: "text webix_ssheet_formula",
	defaults:{
		keyPressTimeout: 25
	},
	$init(config) {
		config.suggest = {
			view: "suggest-formula",
			data: config.suggestData
		};
		this.attachEvent("onKeyPress", (code) => {
			if(code === 13) {
				//ignore Enter key if it was pressed to select some value from suggest
				if ((new Date() - (this._last_value_set || 0)) > 300){
					this.updateCellValue();
					//we have 'enter' hot key to activate editor
					webix.delay(function(){ this.getTopParentView()._table.moveSelection("down"); },this);
				}
			}
		});

		this.attachEvent("onAfterRender", webix.once(function(){
			var node = this.getInputNode();
			var ev =  webix.env.isIE8?"propertychange":"input";

			webix.event(node, ev, webix.bind(this.paintValue, this));
			webix.event(node, "keydown", webix.bind(this.endEdit, this));
		}));
	},
	endEdit(e){
		let code = e.which || e.keyCode;
		let node = this.getInputNode();
		//if it is a formula - force user to finish it by click outside or 'enter'
		if(code == 9 || (code>36 && code<41 && node.value.charAt(0)!=="=")){
			let dir = (code == 40)?"down":(code === 39?"right":(code ==37?"left":"up"));
			if (code === 9)
				dir = "right";

			this.updateCellValue();
			this.getTopParentView()._table.moveSelection(dir);
		}
	},
	paintValue (){
		let master = this.getTopParentView();
		let cell = this.config.activeCell;
		let node = master._table.getItemNode(cell);

		if(cell && node) 
			node.innerHTML = this.getValue();
	},
	updateCellValue() {
		let newv = this.getValue();
		let master = this.getTopParentView();
		let cell = this.config.activeCell;

		if (!cell)
			this.setValue("");
		else {
			if (newv != master.getCellValue(cell.row, cell.column)){
				this.config.value = newv;
				master.setCellValue(cell.row, cell.column, newv);
				master.refresh();
			}
		}
	},
	$setValueHere(value){
		this.setValueHere(value);
	},
	setValueHere(value) {
		this._last_value_set = new Date();

		let node = this.getInputNode();
		if(node.value && node.value.charAt(0)==="="){
			const formula = node.value;
			const cursor = node.selectionStart;
			let str1 = formula.substring(0, cursor);
			let str2 = formula.substring(cursor);
			str1 = str1.replace(/([a-zA-Z(]+)$/, value);

			node.value = str1 + "(" + str2;
			node.setSelectionRange(str1.length + 1, str1.length + 1);
		}
	},
	expectRange(){
		let text = this.getValue().trim();
		let last = text.substr(text.length-1,1);
		return last.match(/[(,]/);
	},
	expectOperator(){
		let text = this.getValue().trim();
		let last = text.substr(text.length-1,1);
		return text.substr(0, 1) == "=" && last.match(/[+&\-/*%=(:,]/);
	},
	setRange(range) {
		let node = this.getInputNode();
		const cursor = node.selectionStart;
		const formula = this.getValue();

		let str1 = formula.substring(0, cursor) + range;
		let str2 = formula.substring(cursor);

		node.value = str1 + str2;
		node.setSelectionRange(str1.length + 1, str1.length + 1);
		this.focus();
	}
}, webix.ui.text);