import * as methods from "./math/methods";

let methodsName = [];
for(let i in methods.methods)
	if( i!="__esModule") methodsName.push(i);

export function init(view){
	view.attachEvent("onComponentInit", () => ready(view));

	return {
		view:"toolbar",
		css:"webix_ssheet_toolbar",
		elements:[
			{ view:"label", label:"Edit: ", width:60},
			{
				view:"live-editor",
				disabled:true,
				id:"liveEditor", 
				suggestData: methodsName
			}
		]
	};
}

function ready(view){
	var editor = view.$$("liveEditor");

	//block native editor, and move focus to the custom input
	//edit by key press
	view._table.attachEvent("onBeforeEditStart", function(cell){
		let mode = view._table.$anyKey;
		view._table.$anyKey = false;

		return startEdit(this, cell, mode);
	});
	//edit by enter key
	view._table.attachEvent("onBeforeLiveEditor", function(cell){
		startEdit(this, cell, false);
	});
	//edit by dbl-click
	view._table.attachEvent("onItemDblClick", function(cell){
		startEdit(this, cell, false);
	});

	view.attachEvent("onCellChange", (r,c,v) => {
		var cell = editor.config.activeCell;
		if (cell && cell.row == r && cell.column == c)
			editor.setValue(v);
	});

	view.attachEvent("onAfterSelect", (data) => {
		if (!view.$handleSelection){
			let cell = data[0];
			fillEditor(view._table, cell);
		}
	});

	view.attachEvent("onReset", () => view.$handleSelection = null);

	view.attachEvent("onAction", function(name, options){
		if(name == "lock" || name == "filter" || name == "dropdown")
			disableEditor(options.row,  options.column);
	});

	function startEdit(table, cell, clear){
		var editor = table.getColumnConfig(cell.column).editor;
		//do not interfere with custom editors
		if (editor == "text"){
			fillEditor(table, cell, clear);
			return false;
		}
		return true;
	}

	function disableEditor(row,column){
		if (view.getCellEditor(row, column) || view.isCellLocked(row,column)){
			editor.setValue("");
			editor.disable();
			return true;
		}
		return false;
	}

	function fillEditor(table, cell, clear){
		if(disableEditor(cell.row, cell.column))
			return;
		editor.enable();
		editor.config.activeCell = cell;
		editor.setValue( clear ? "" : view.getCellValue(cell.row, cell.column) );
		editor.focus();

		view.$handleSelection = function(a,b,st,en){ return pasteRange(table, st, en, a); };
	}

	function pasteRange(view, st, en, cell){
		if (st == en){
			if (!editor.expectOperator()) return endEdit(cell);
			editor.setRange(st);
			webix.delay(() => editor.focus());
		}
		else{
			if (!editor.expectRange()) return endEdit(cell);
			editor.setRange(`${st}:${en}`);
			webix.delay(() => editor.focus());
		}

		return false;
	}

	function endEdit(st){
		if (editor.isEnabled()){
			editor.updateCellValue();
			editor.setValue(st ? view.getCellValue(st.row, st.column) : "");
		}
		return true;
	}

}

