import * as cop from "./operations/column_operations";
import * as nms from "./helpers/column_names";
import * as fmt from "./operations/formats";

export function init(view, extra){
	view.attachEvent("onComponentInit", () => ready(view));

	var datatable = {
		view:"datatable", id:"cells", css:"webix_ssheet_table webix_data_border wss_"+view.$index,
		headerRowHeight: webix.skin.$name == "contrast" || webix.skin.$name == "flat" ? 24 : 20,
		//select:"cell",
		spans: true,
		leftSplit: 1,
		areaselect:true,
		editable:true,
		editaction:(extra.liveEditor ? "custom" : "dblclick"),
		navigation:true
	};

	//enable clipboard by default
	if (extra.clipboard !== false){
		extra.clipboard = "custom";
		if(extra.clipboardDecimalDelimiter)
			extra.templateCopy = function(item) {
				if(webix.rules.isNumber(item))
					item = item.toString().replace(".", extra.clipboardDecimalDelimiter);
				return item;
			};
	}
	if (extra)
		datatable = webix.extend(datatable, extra, true);

	return datatable;
}

function ready(view){
	var grid = view._table;

	var showHandler = webix.event(document.body, "mousemove", function(){
		var thread;
		return function(e) {
			clearTimeout(thread);
			thread = webix.delay(function(e){
				if(view.comments._activeComment.editStatus)
					return;

				var cell = grid.locate(e);
				var visible = view.comments.commentsView && view.comments.commentsView.isVisible();

				if(cell && view.comments.get(cell.row, cell.column)){
					var activeCell = view.comments._activeComment.cell;
					if((activeCell && (activeCell.row != cell.row || activeCell.column != cell.column)) || !visible)
						view.callEvent("onCommand",[{id: "add-comment", cell, viewonly: true }]);
				}
				else if(visible && !view.comments.commentsView.$view.contains(e.target))
					view.callEvent("onCommentHide", []);
			}, true, [e], 250);
		};
	}());

	view.attachEvent("onDestruct", () => webix.eventRemove(showHandler));

	//in grid math
	if (view.config.math){
		grid.config.editMath = true;
	}

	//saving value after edit
	grid.attachEvent("onBeforeEditStop", (st, ed) => {
		//ignore empty cells
		if (st.old === webix.undefined && st.value === "") return;

		if (st.value != st.old){
			view.setCellValue(ed.row, ed.column, st.value);
			grid.refresh();
		}
		st.value = st.old;
	});
	
	//column drag event checking
	let dragStartColumn;
	grid.$view.firstChild.addEventListener("mousedown", (e)=>{
		//column ids are starting from 0, thats why we adding 1
		dragStartColumn = e.target.parentNode.cellIndex + 1;
	});
	grid.$view.firstChild.addEventListener("mouseup", (e)=>{
		const dragEndColumn = e.target.parentNode.cellIndex + 1;
		if (dragStartColumn && dragStartColumn !== dragEndColumn) {
			cop.selectColumn(dragStartColumn, view, dragEndColumn);
			dragStartColumn = undefined;
		}
	});

	grid.attachEvent("onEnter", function(){
		if (grid.getEditor()){
			webix.delay(function(){
				grid.moveSelection("down");
			});
		}
	});

	//prevent editing of locked cells
	view.attachEvent("onBeforeEditStart", (row, column) => !view.isCellLocked(row, column));
	grid.attachEvent("onBeforeEditStart", (editor) => 
		view.callEvent("onBeforeEditStart", [editor.row, editor.column]) );

	//column and row selection
	grid.attachEvent("onBeforeSelect", (id) => id.column != "rowId" );
	grid.attachEvent("onBeforeBlockSelect", (start, end, finalStep) => {
		if (finalStep &&
		(start.column !== "rowId" || end.column !== "rowId")) {
			if (start.column === "rowId") start.column = 1;
			if (end.column === "rowId") end.column = 1;
		}
		if(start.column !== "rowId" || end.column !== "rowId"){
			cop.highlightColRow(start,end, view);
			return true;
		}
		return false;
	});
	grid.attachEvent("onSelectChange", () => {
		let ids = grid.getSelectedId(true);
		if(ids.length){
			let start = ids[0];
			let end = ids[ids.length-1]?ids[ids.length-1]:ids[0];
			cop.highlightColRow(start, end, view);
		} else {
			let data = {row:0, column:0};
			cop.highlightColRow(data, data, view);
		}
	});

	grid.attachEvent("onItemDblClick", (id) => {
		if (id.column === "rowId")
			cop.adjustRow(id.row, view);
	});

	let lastHeaderClick = 0;
	grid.attachEvent("onHeaderClick", (id, e) => {
		if(id.column == "rowId"){
			cop.selectAll(view);
			return;
		}
		let headerClick = new Date();
		let dblClick = (headerClick - lastHeaderClick <= 300);

		if (dblClick) {
			cop.adjustColumn(id.column, view);
		} else {
			lastHeaderClick = headerClick;
			if (e.shiftKey) {
				cop.selectColumns(id.column, view);
			} else {
				cop.selectColumn(id.column, view);
			}
		}
	});

	//select rows by shift click
	var lastClickedRow = null;
	grid.attachEvent("onItemClick", (cell, e) => {
		if (cell.column === "rowId") {
			if (!e.shiftKey)
				cop.selectRow(cell.row, view);
			else {
				if (!lastClickedRow)
					cop.selectRow(cell.row, view);
				else
					cop.selectRow(lastClickedRow, view, cell.row);
			}
			lastClickedRow = cell.row;
		}
	});

	//reset API
	view.attachEvent("onReset", () => reset(view));

	view.attachEvent("onBeforeSheetShow", () => grid.editStop());

	grid.attachEvent("onBlur", function(){
		//after focus moved out, check and if it is somewhere
		//on the spreadsheet controls them move focus back to datatable
		webix.delay(function(){
			var target = document.activeElement;
			if (target && target.tagName == "INPUT") return;

			var focus = webix.UIManager.getFocus();
			var need_focus = focus &&
				focus != grid &&
				focus.getTopParentView &&
				focus.getTopParentView() === view;
				
			if (need_focus)
				webix.UIManager.setFocus(grid);

		}, this, [], 100);
	});

	setDefaultCss(view);
}

function cell_template(view, obj, common, value, column){
	var format = obj.$cellFormat ? obj.$cellFormat[column.id] : null;

	var commented = view.comments.get(obj.id, column.id) ? "<div class='ssheet_commented_sign'></div>" : "";

	if (format){
		var cell = { css:"" };
		var helper = fmt.getFormat(format);
		if (helper){
			let parsed = parseFloat(value);
			if (!isNaN(parsed)){
				var newvalue = helper(parsed, cell);
				if (cell.css)
					return "<div class='"+cell.css+"'>"+newvalue+"</div>"+commented; 
				return newvalue+commented;
			}
		}
	}
	if(webix.isUndefined(value) || value === null)
		value = "";
	return value+commented;
}

export function reset(view){
	var grid = view.$$("cells");
	grid.clearAll();

	var columns = view.config.columnCount;
	var rows = view.config.rowCount;

	var cols = [{
		id:"rowId", header:"", width:40,
		css:"sheet_column_0",
		template(el) {
			return el.id;
		}
	}];

	for (let i=1; i <= columns; i++) {
		cols.push({
			id:i,
			editor:"text",
			header: {
				text: nms.encode[i],
				css: view._hideColumn && view._hideColumn.indexOf(i+1) >= 0 ? "webix_ssheet_hide_column" : ""
			},
			template:function(obj, common, value, column){
				return cell_template(view, obj, common, value, column);
			}
		});
		view.callEvent("onColumnInit",[cols[i]]);
	}

	grid.refreshColumns(cols);

	if(view._hideColumn && view._hideColumn.length)
		view._hideColumn.map(id => grid.hideColumn(id));


	var data = [];
	for (let i=1; i<=rows; i++)
		data.push({ id:i });

	grid.parse(data);

	if(view._hideRow && view._hideRow.length) {
		grid.filter(function(obj){
			if(view._hideRow.indexOf(obj.id) === -1) {
				return true;
			}else {
				if(obj.id - 1 === 0){
					let cell = view.$$("cells").getColumnConfig("rowId").header[0];
					cell.css = (cell.css || "") + " webix_ssheet_hide_row";
					view.$$("cells").refreshColumns();
				}else {
					view.$$("cells").addCellCss(obj.id - 1, "rowId", "webix_ssheet_hide_row");
				}
				return false;
			}
		});
	}
}

// nowrap by default
function setDefaultCss(view){
	webix.html.addStyle("#"+view._table.$view.id+".webix_dtable .webix_cell { white-space:nowrap;}");
}