import {isCellSelected, isColSelected,isRowSelected,selectRow,selectColumn} from "./operations/column_operations";

export function init(view){
	view.attachEvent("onComponentInit", () => view.$$("context").attachTo(view._table.$view));

	var locale = webix.i18n.spreadsheet.menus;
	const lists = {
		data:[
			{ id:"clear",		value: locale["clear"], submenu:[
				{id: "clear-value",              value: locale["clear-value"]},
				{id: "clear-style",              value: locale["clear-style"]},
				{id: "clear-conditional-formats", value: locale["clear-conditional-formats"]},
				{id: "clear-dropdown-editors",    value: locale["clear-dropdown-editors"]},
				{id: "clear-comments",    value: locale["clear-comments"]},
				{ $template: "Separator"},
				{id: "clear-all",                value: locale["clear-all"]}
			]},
			{ id:"lock-cell",		value: locale["lock-cell"]},
			{ $template: "Separator"},
			{ id:"sort",	value: locale.sort, submenu:[
				{id: "sort-asc", value: locale["sort-asc"]},
				{id: "sort-desc", value: locale["sort-desc"]}
			]},
			{id:"create-filter",		value: locale["create-filter"]},
			{ $template: "Separator"},
			{ id:"add-range",		value: locale["add-range"]},
			{ id:"add-link",		value: locale["add-link"]},
			{ id:"add-comment",		value: locale["add-comment"]}
		],
		column:[
			{ id:"add",		group:"column", value:locale["insert-column"]},
			{ id:"del",		group:"column", value:locale["delete-column"]},
			{ id:"show",	group:"column", value:locale["show-column"]},
			{ id:"hide",	group:"column", value:locale["hide-column"]}
		],
		row:[
			{ id:"add",		group:"row", value:locale["insert-row"]},
			{ id:"del",		group:"row", value:locale["delete-row"]},
			{ id:"show",	group:"row", value:locale["show-row"]},
			{ id:"hide",	group:"row", value:locale["hide-row"]}
		]
	};


	var context = {
		view: "contextmenu",
		id: "context",
		padding:0,
		sizeToContent:true,
		submenuConfig:{
			padding:0,
			on:{
				onBeforeShow:function(){
					this.sizeToContent();
				}
			}
		},
		data:[],
		on:{
			onMenuItemClick(id) {
				view.callEvent("onCommand", [this.getMenuItem(id)]);
			},
			onBeforeShow(pos) {
				webix.callEvent("onClick", []);
				var mode, trg;

				trg = view._table.locate(pos);
				if (!trg) return false;

				mode = "";
				if (trg.header && trg.column !== "rowId"){
					if (!isColSelected(trg.column, view))
						selectColumn(trg.column, view);
					mode = "column";
				} else if (trg.row){
					if (trg.column === "rowId"){
						if (!isRowSelected(trg.row, view))
							selectRow(trg.row, view);
						mode = "row";
					} else {
						if (!isCellSelected(trg.row, trg.column, view))
							view._table.addSelectArea(trg, trg);
						mode = "data";
					}
				}

				if (mode){
					var data = getMenuData(view, mode, lists[mode]);
					if (data){
						this.clearAll();
						this.parse(data);
						webix.html.preventEvent(pos);
						return true;
					}
				}
				return false;
			}
		}
	};

	view.callEvent("onViewInit", ["context", context]);
	view.ui(context);
}

export function getMenuData(view, name, data){
	var ev = { area:name, data:data };
	if (view.callEvent("onContextMenuConfig", [ev]))
		return ev && ev.data;
	return null;
}