var types = [".xls",".xlt",".xla",".xlsx",".xlsm",".xltx",".xltm",".xlam",".xlsb"];

export function init(view){
	var uploader;
	if (window.XMLHttpRequest && (new XMLHttpRequest()).upload){
		uploader = webix.ui({
			view:"uploader",
			apiOnly:true,
			accept: types.join()
		});

		uploader.attachEvent("onBeforeFileAdd",webix.bind(function(upload){
			if(types.indexOf("."+upload.type)>-1){
				view.reset();
				view.parse(upload.file, "excel");
			}
			return false;
		},this));
	}

	view.attachEvent("onCommand", (obj) => {
		if (obj.id === "excel-import")
			startImport(view, uploader);
	});
}

function startImport(view, uploader){
	if (!uploader)
		webix.alert(webix.i18n.spreadsheet.labels["import-not-support"]);
	else
		webix.delay(() => uploader.fileDialog());
}