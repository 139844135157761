webix.protoUI({
	name:"multicheckbox",
	defaults:{
		padding:0,
		type:"clean",
		borderless:true,
		elementsConfig:{
			labelWidth:10
		}
	},
	$init:function(config){
		config.rows = [{height:0.000001}];
		this.$ready.push(function(){
			this._initOnChange();
			this.setValue(config.value);
		});
	},
	_initOnChange(){
		this.attachEvent("onChange", function(newv){
			this.blockEvent();
			if(this.$eventSource.config.name ==="$all"){
				for(var i in this.elements)
					this.elements[i].setValue(newv);
				this.getChildViews()[0].getBody().getChildViews()[0].setValue(1);
			}
			else{
				var count = this.getValue().length;
				if(count === this._count)
					this.elements.$all.setValue(1);
				if(count < this._count)
					this.elements.$all.setValue(0);
				if(count ===0)
					this.elements[this._findNext(this.$eventSource.config.name)].setValue(1);
			}	
			this.unblockEvent();
		});
	},
	_findNext:function(name){
		for(var i in this.elements)
			if(i !==name && i !=="$all")
				return i;
	},
	setValue:function(value){
		var keys = [];
		for(let i in value) keys.push(i);

		value = value || {};
		this.elements = {};
		this._count = keys.length;

		if(typeof value !=="object" || !keys.length){
			if(this.getChildViews().length >1)
				webix.ui([{height:0.000001}], this);
		}
		else{
			var elements = [], base = [], maxHeight = 400;
			for(let i in value) 
				base.push({ view:"checkbox", labelRight:i, name:i});
			var baseHeight = base.length*webix.skin.$active.inputHeight;

			elements.push({
				view:"scrollview", body:{rows:base},
				scroll:baseHeight>maxHeight,
				height:(baseHeight>maxHeight?maxHeight:baseHeight)
			});

			elements.push({ template:"<div class='ss_sep_line'></div>", height:10});
			elements.push({ view:"checkbox", labelRight:webix.i18n.spreadsheet.labels["export-all-sheets"], name:"$all"});

			webix.ui(elements, this);
			for(let i in value)
				if(value[i]) this.elements[i].setValue(value[i]);
		}
	},
	getValue:function(){
		var value = this.getValues(), output = [];
		for(var i in value)
			if(value[i] && i !=="$all") output.push(i);
		return output;
	}
}, webix.ui.form);