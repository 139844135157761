const defaultMenu = [
	{ id:"file", submenu:[
		{id: "sheet", submenu: [
			{id: "new-sheet"},
			{id: "copy-sheet"},
			{id: "remove-sheet"}
		]},
		{id: "excel-import"},
		{id: "excel-export"}
	]},
	{ id:"edit", submenu:[
		{id: "add-range"},
		{id: "add-dropdown"},
		{id: "add-link"},
		{id: "lock-cell"},
		{id: "conditional-format"},
		{id: "clear", submenu:[
			{id: "clear-value"},
			{id: "clear-style"},
			{id: "clear-conditional-formats"},
			{id: "clear-dropdown-editors"},
			{id: "clear-comments"},
			{ $template:"Separator" },
			{id: "clear-all"}
		]}
	]},
	{ id:"insert", submenu:[
		{id: "add-image"},
		{id: "add-sparkline"},
		{id: "add-comment"}
	]},
	{ id:"data", submenu:[
		{id: "sort", submenu: [
			{id: "sort-asc"},
			{id: "sort-desc"}
		]},
		{id: "create-filter"}
	]},
	{
		id: "view", submenu:[
			{id: "columns", submenu: [
				{id: "insert-column"},
				{id: "delete-column"},
				{id: "show-column"},
				{id: "hide-column"}
			]},
			{id: "rows", submenu: [
				{id: "insert-row"},
				{id: "delete-row"},
				{id: "show-row"},
				{id: "hide-row"}
			]},
			{ $template:"Separator" },
			{id: "freeze-columns"},
			{id: "freeze-rows"},
			{id: "hide-gridlines"},
			{id: "hide-headers"}
		]
	}
];

const ui = {
	getMenuData: function(menu){
		var i;
		for(i=0; i < menu.length; i++){
			if(typeof menu[i] == "string")
				menu[i] = {id: menu[i]};
			if(!menu[i].value)
				menu[i].value = webix.i18n.spreadsheet.menus[menu[i].id];
			if(menu[i].submenu)
				menu[i].submenu = this.getMenuData(menu[i].submenu);
		}

		return menu;
	}
};

export function init(view){
	view.attachEvent("onComponentInit", () => ready(view));

	var config = view.config,
		menu = {
			view: "menu",
			borderless: true,
			css: "webix_ssheet_menu",
			id: "menu",
			autowidth: true,
			type:{
				height: 40
			},
			data: ui.getMenuData(webix.isArray(config.menu)?config.menu : defaultMenu)
		};

	view.callEvent("onViewInit", ["menu", menu]);
	return menu;
}


function ready(view){
	if(view.$$("menu"))
		view.$$("menu").attachEvent("onMenuItemClick",(id) => callAction(view,id));
}

const actionMap = {
	"undo": function (view) {
		view.undo();
	},
	"redo": function (view) {
		view.redo();
	},
	"insert-column": function (view) {
		view.insertColumn();
	},
	"delete-column": function (view) {
		view.deleteColumn();
	},
	"show-column": function (view) {
		view.hideColumn(null,false);
	},
	"hide-column": function (view) {
		view.hideColumn(null,true);
	},
	"insert-row": function (view) {
		view.insertRow();
	},
	"delete-row": function (view) {
		view.deleteRow();
	},
	"show-row": function (view) {
		view.hideRow(null,false);
	},
	"hide-row": function (view) {
		view.hideRow(null,true);
	},
	"freeze-columns": function(view){
		view.freezeColumns();
	},
	"hide-gridlines": function(view){
		view.hideGridlines("toggle");
	},
	"hide-headers": function(view){
		view.hideHeaders("toggle");
	}
};

function callAction(view,id){
	if(view.callEvent("onMenuItemClick",[id])){
		if(actionMap[id])
			actionMap[id](view);
		else
			view.callEvent("onCommand",[{id: id}]);
	}
}