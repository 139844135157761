import * as aiw from "./image";
import * as asw from "./sparkline";
import * as rgs from "./ranges";
import * as dpd from "./dropdown";
import * as fmt from "./format";
import * as cnd from "./conditional";
import * as exp from "./export";
import * as lnk from "./link";
import * as pnt from "./print";
import * as cmt from "./comments";

var bases = [ aiw, asw, rgs, dpd, fmt, cnd, exp, lnk, pnt, cmt ];
var dialogs = {};

export function init(view){
	if(view.config.readonly)
		bases = [cmt];
	for (var i = 0; i < bases.length; i++)
		dialogs[bases[i].action] = new bases[i].DialogBox(view);

	view.attachEvent("onCommand", function(action){
		var box = dialogs[action.id];
		if (box) box.open(action);
	});
}