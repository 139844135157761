import 		{methods} 		from "./methods";
import * as rgs 			from "./ranges";
import * as psh 			from "./placeholder";
import 		{changeRange} 	from "../helpers/column_names";
import * as sheets 			from "./sheet";
import 		{_parse} 		from "./cache";


export function init(view){
	var triggers = [];
	var backtrak = [];

	//math operations object
	var core = get_core(view);

	//init named ranges
	view.ranges = core.ranges = new rgs.Ranges(view);
	//init placeholders
	core.p = psh.init(view);

	view.attachEvent("onReset", () => {
		triggers = [];
		backtrak = [];
		view.ranges.clear();
	});

	view.attachEvent("onCellChange", recalckCell);
	view.attachEvent("onMathRefresh", recalckAll);

	view.attachEvent("onDataSerialize", (data) => _serialize(view, data));
	view.attachEvent("onDataParse", (data) => _dataParse(view, data));

	view.attachEvent("onAction", (action, p)=> {
		if(action == "before-grid-change")
			updatePosition(p.name,p.inc,p.data, p.start);
	});

	var solvedMath;
	var solvedCounter;
	//execute math handler
	function applyMath(r, c, handler){
		const row = view.getRow(r);
		const value = _execute(handler, core);
		row[c] = value;

		
		const tkey = c*100000000+r;
		const count = solvedCounter[tkey] = (solvedCounter[tkey] || 0) + 1;

		if (solvedMath[tkey] === value || count > 1000){
			//prevent infinity loops
			//if cell was already calculated with the same value, do not recaculate dependent cells
			//in some cases we have have non-stable loop, where value of math is different each time
			//counter limits calculation for 1000 itterations per cell
			return;
		}

		solvedMath[tkey] = value;
		//check if we have related cells, process their math as well
		check_trigger(r, c);
	}

	//check and run triggers
	function check_trigger(srow, scolumn){
		//triggers is a matrix[row][column]
		//store array of coordinates of related cells

		const line = triggers[srow];
		if (line){
			const block = line[scolumn];
			if (block){
				for (var i = 0; i < block.length; i++){
					const {row, column, handler} = block[i];
					if (row != srow || column != scolumn){
						applyMath(row, column, handler);
					}
				}
			}
		}
	}

	function recalckAll(){
		var grid = view.$$("cells");
		var state = grid.getState();
		var columns = state.ids.concat(state.hidden);

		grid.eachRow(function(obj){
			var item = this.getItem(obj);
			for (var i=1; i<columns.length; i++){
				var key = columns[i];
				var value = item["$"+key];
				if (value)
					recalckCell(obj, key, value);
			}
		}, true);

		grid.refresh();
	}

	function recalckCell(r,c,value){
		//check if changed cell was a math cell, based on some other cells
		//clean triggers in such case
		var line = backtrak[r];
		if (line && line[c])
			remove_triggers(triggers, backtrak, c, r);

		//if new value is a math, calculate it and store triggers
		if (value && value.toString().indexOf("=") === 0){
			var formula = _parse(value, core, "");
			var row = view.getRow(r);
			row[c] = _execute(formula.handler, core);
			row["$"+c] = formula.text;

			if (formula.triggers.length){
				add_triggers(triggers, backtrak, formula.triggers, 
					{ row: r, column: c, handler: formula.handler }, view.getActiveSheet());
			}

		}
		//check if we have some other cells, related to the changed one
		solvedMath = {};
		solvedCounter = {};
		check_trigger(r, c);
	}

	sheets.init(view, core, { parse: _parse, execute: _execute });
}

export function calculate(view, value){
	var core = get_core(view);
	var formula = _parse(value, core, "");
	value = _execute(formula.handler, core);
	return value;
}

//add new triggers
function add_triggers(trs, back, adds, cell, active){
	//trs - matrix of triggers
	//back - matrix of backlinks
	//adds - list of triggers
	var blist = [];
	for (var i = 0; i < adds.length; i++){
		var line = adds[i];
		//line = [start_row, start_column, end_row, end_column, sheet]

		//ignore triggers from passive cells
		if (line[4] !== "" && line[4] !== active)
			continue;

		for (var j = line[0]; j<=line[2]; j++){
			var step = trs[j];
			if (!step) step = trs[j]=[];
			for (var k = line[1]; k <= line[3]; k++){
				var block = step[k];
				if (!block)
					block = step[k] = [];

				blist.push([j, k]);
				block.push(cell);
			}
		}
	}

	//store back-relations, for easy trigger removing 
	add_backtrack(back, cell.row, cell.column, blist);
}

//store backtrack relations as a matrix
function add_backtrack(back, row, column, adds){
	var line = back[row];
	if (!line)
		line = back[row] = [];
	line[column] = adds;
}

//remove unused triggers
function remove_triggers(trs, back, c, r){
	//get list of triggers from backtrack structure
	var adds = back[r][c];
	back[r][c] = null;

	//delete triggers
	for (var i = adds.length - 1; i >= 0; i--) {
		var cell = adds[i];
		var block = trs[cell[0]][cell[1]];
		
		for (var j = block.length-1; j >= 0; j--){
			var bcell = block[j];
			if (bcell.row == r && bcell.column == c)
				block.splice(j,1);
		}
	}
}

//run math function
function _execute(formula, core){
	var value;
	var error = webix.i18n.spreadsheet.table["math-error"];
	try {
		value = formula.call(core);	
	} catch(e){
		//some error in the math code
		return error;
	}

	if (typeof value === "number"){
		if(isNaN(value)) return error;
		//round values to fix math precision issue in JS
		return Math.round(value*100000)/100000;
	} else if (typeof value !== "string"){
		return value ? value.toString() : error;
	} else
		return value;
}

//converts cell references to code
function get_core(view){
	var active = sheets.getAccessor(view);

	return {
		rs:function(sheet, r1, c1, r2, c2){
			return sheets.getAccessor(view, sheet).getRange(r1,c1,r2,c2);
		},
		r:active.getRange,
		vs:function(sheet, r, c){
			return sheets.getAccessor(view, sheet).getValue(r,c);
		},
		v:active.getValue,
		m:methods,
		p:{}
	};
}

function _serialize(view, obj){
	obj.ranges = view.ranges.serialize();
}

function _dataParse(view, obj){
	view.ranges.parse(obj.ranges);
}

function updatePosition(name, inc, data, start){
	var ranges = data.ranges;
	for(let i=0; i < ranges.length; i++)
		ranges[i][1] = changeRange(ranges[i][1],name,inc, start);
}