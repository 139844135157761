webix.protoUI({
	name: "suggest-formula",
	defaults: {
		fitMaster: false,
		width: 200,
		filter(item, value) {
			const trg = webix.$$(this.config.master).getInputNode();
			const cursor = trg.selectionStart;
			const val = trg.value;

			if(val.charAt(0) !== "=") return;

			let str1 = val.substring(0, cursor).match(/([a-zA-Z]+)$/);
			let str2 = val.charAt(cursor).search(/[^A-Za-z0-9]/);

			if(str1 && (cursor === val.length || str2 === 0)){
				value = str1[0];
			}
			return item.value.toString().toLowerCase().indexOf(value.toLowerCase()) === 0;
		}
	},
	$init() {
		this.attachEvent("onBeforeShow", (node) => {
			if(node.tagName){
				//only for formulas
				if(!node.value || node.value.charAt(0) !== "=") return false;
				
				const sizes = webix.html.offset(node);
				const symbolLength = 9;

				const y = sizes.y + sizes.height;
				const x = sizes.x + (node.selectionStart * symbolLength);

				webix.ui.popup.prototype.show.apply(this, [{x, y}]);
				return false;
			}
		});
	},
	setMasterValue(data, refresh) {
		const text = data.id ? this.getItemText(data.id) : (data.text||data.value);
		let master = webix.$$(this.config.master);
		master.setValueHere(text);
		
		if (!refresh)
			this.hide(true);

		this.callEvent("onValueSuggest", [data, text]);
	},
	$enterKey: function(popup) {
		if (popup.isVisible())
			return webix.ui.suggest.prototype.$enterKey.apply(this, arguments);
	}
}, webix.ui.suggest);
