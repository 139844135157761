export function insertRow(row){
	callCommand(this, "add", "row", row);
}

export function deleteRow(row){
	callCommand(this, "del", "row", row);
}

export function insertColumn(column){
	callCommand(this, "add", "column", column);
}

export function deleteColumn(column){
	callCommand(this, "del", "column", column);
}

function getCells(view, id, group){
	if(!id){
		let area = view.$$("cells").getSelectArea();
		if(area)
			id =  [area.start[group],area.end[group]];
	}
	else if(!webix.isArray(id)){
		id =  [id,id];
	}
	return id;
}

function callCommand(view, name, group, id){
	var start = {}, end = {};
	id = getCells(view, id, group);
	start[group] = id[0];
	end[group] = id[1];
	view.callEvent("onCommand", [{id:name,  group:group}, start, end]);
}