import group from "../operations/undo_group";

export function  freezeColumns(column){
	const grid = this.$$("cells");
	const oldColumn = grid.config.leftSplit-1;

	if (column === false)
		column =0;

	if( webix.isUndefined(column) ){
		let sel = this.getSelectedId(true);
		column = sel.length?sel[0].column:0;
	}

	if(grid.config.leftSplit>1){
		var diff = column - oldColumn;
		if(!diff)
			column = 0;
	}

	group.set(function(){
		checkSpans(this, "column", column);
		grid.unselect();
		const rowId = grid.config.columns[0].id == "rowId" ? 1 : 0;
		grid.define("leftSplit", column?column+rowId:rowId);
		grid.refreshColumns();

		this.callEvent("onAction", [ "freeze-column", { value: oldColumn, newValue: column}]);
	},this);
}

export function freezeRows(row){
	const grid = this.$$("cells");
	const oldRow = grid.config.topSplit;
	
	//if no row selected or at split row
	if(oldRow == row) row = 0;

	group.set(function(){
		checkSpans(this, "row", row);
		grid.unselect();
		grid.define("topSplit", row || 0);
		grid.refreshColumns();

		this.callEvent("onAction", [ "freeze-row", { value: oldRow, newValue: row}]);
	},this);
}

function checkSpans(view, type, num){
	const grid = view.$$("cells");

	if(type == "row"){
		let start = 1;
		let end = view.config.columnCount;
		for(let i = start; i <= end; i++){
			const span = grid.getSpan(num, i);
			if(span){
				const spanSize = (span[0]*1) + span[3] - 1;
				if(spanSize>num)
					changeSpan(view, span, span[2], num-span[1]+1);
			}
		}
	}
	else{//column
		let start = grid.data.order[0];
		let end = grid.data.order.length;
		for(let i = start; i <= end; i++){
			const span = grid.getSpan(i, num);
			if(span){
				const spanSize = (span[1]*1) + span[2] - 1;
				if(spanSize>num)
					changeSpan(view, span, num-span[0]+1, span[3]);
			}
		}
	}
}

function changeSpan(view, span, x, y){
	view.splitCell(span[0], span[1]);
	view.combineCells({
		cell:{
			row:span[0],
			column:span[1]
		},
		x:x,
		y:y
	});
}