export function init(view){
	//parsing initial data
	view.attachEvent("onDataParse", (data) => _parse(view, data));
	view.attachEvent("onDataSerialize", (data, config) => _serialize(view, data, config));

	// undo
	view.attachEvent("onUndo", (type, row, column, value) => {
		if(type == "value")
			_undoValue(view, row, column, value);
	});
}

function _serialize(view, obj, config){
	var math = !config || config.math!==false;
	var data = [];
	var grid = view.$$("cells");
	var state = grid.getState();
	var columns = state.ids.concat(state.hidden);

	// remove "rowId" column
	columns.splice(columns.indexOf("rowId"), 1);

	grid.eachRow(function(obj){
		var item = this.getItem(obj);
		for (var i=0; i<columns.length; i++){
			var key = columns[i];
			var value = item[key];
			var css = item.$cellCss ? (item.$cellCss[key] || "") : "";

			//serialize instead of value if defined
			if (math)
				value = item["$"+key] || value;

			//put not empty values in serialization
			var hasValue = value || value === 0;
			if (hasValue || css) {
				data.push([obj*1, key*1, (hasValue ? value : ""), css]);
			}
		}
	}, true);

	obj.table = {
		frozenColumns : grid.config.leftSplit-(state.hidden.indexOf("rowId") != -1 ? 0 : 1),
		frozenRows : grid.config.topSplit
	};

	obj.data = data;
}

function _parse(view, obj){
	var grid = view.$$("cells");

	var tconfig = obj.table || { frozenColumns : 0, frozenRows : 0 };
	if (tconfig){
		if (!webix.isUndefined(tconfig.frozenColumns) && (tconfig.frozenColumns+1) != grid.config.leftSplit)
			view.freezeColumns(tconfig.frozenColumns);
		if (!webix.isUndefined(tconfig.frozenRows) && tconfig.frozenRows != grid.config.topSplit)
			view.freezeRows(tconfig.frozenRows);
	}

	if(obj.sizes)
		grid.define("fixedRowHeight", false);

	for (var i = 0; i < obj.data.length; i++){
		var [ row, column, value, style] = obj.data[i];

		var item = grid.getItem(row);
		item[column] = value;
		if (style){
			item.$cellCss = item.$cellCss || {};
			item.$cellCss[column] = style;
		}
		view.callEvent("onCellChange", [row, column, value]);
	}
}

function _undoValue(view, row, column, value){
	view.setCellValue(row, column, value);
}
