import * as m1  from "./add_row_col";
import * as m6  from "./clipboard";
import * as m12 from "./column_operations";
import * as m2  from "./conditional_formats";
import * as m16a from "../math/math";
import * as m17 from "./data";
import * as m18 from "./dropdown";
import * as m7  from "./formats";
import * as m13 from "./hide_row_col";
import * as m14 from "./lock_cell";
import * as m3  from "./resize";
import * as m9  from "./selection";
import * as m10 from "./sorting";
import * as m16 from "./spans";
import * as m19 from "./states";
import * as m5  from "./styles";
import * as m11 from "./undo";
import * as m20 from "./import";
import * as m21 from "./filter";
import * as m22 from "./print";
import * as m23 from "./export";
import * as m24 from "./load";
import * as m25 from "./clear_styles";
import * as m26 from "./comments";
import * as m27 from "./save";

export function operations(view){
	//order in some cases does matter
	//resize must be applied after math, to size data by the result of math calculation
	var all = [m1,m2,m5,m6,m7,m9,m10,m11,m12,m13,m14,m16,m16a,m3,m17,m18,m19,m20,m21,m22,m23,m24,m25,m26,m27];
	for (var i = 0; i < all.length; i++)
		if (all[i].init)
			all[i].init(view);
}